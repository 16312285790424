import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocationService } from '@services/location.service';

import { Observable, of } from 'rxjs';
import { Location } from 'src/app/models/location.model';

export const locationResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  locationService: LocationService = inject(LocationService),
): Observable<Location> => {
  const locationUuid = route.paramMap.get('locationUuid');
  // TODO: guard clause for locationUuid + display error message if not present or invalid

  const location = locationService.getLocation();
  if (location.id) {
    console.log('locationResolverService - return cached location');
    return of(location);
  } else {
    console.log('locationResolverService - do the http call to fetch location');
    return locationService.fetchLocation(locationUuid);
  }
};
