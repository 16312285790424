import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { LayoutComponent } from './features/layout/layout.component';
import { takeUntil } from 'rxjs';
import { SubscribedComponent } from './lib/subscribed.component';
import { PreloaderService } from '@services/preloader.service';
import { LocationService } from '@services/location.service';
import { DateTimeUtils } from './lib/date-time-utils';
import { DateConfigService } from '@services/date-config.service';
import { LoadingComponent } from './features/shared/loading/loading.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, NzSpinModule, NzLayoutModule, LayoutComponent, LoadingComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent extends SubscribedComponent implements OnInit, AfterViewInit {
  title = 'widget';

  constructor(
    private ref: ChangeDetectorRef,
    private preloader: PreloaderService,
    private locationService: LocationService,
    private dateTimeUtils: DateTimeUtils,
    private dateConfigService: DateConfigService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.locationService.locationChanged.pipe(takeUntil(this.destroyed$)).subscribe((location) => {
      if (!location) return;
      const firstDayOfWeek = this.dateTimeUtils.getWeekStartForCountry(location.countryCode); // Returns 0 for Sunday, 1 for Monday
      this.dateConfigService.updateDateConfig(firstDayOfWeek);
      this.ref.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.preloader.removePreLoader();
    this.ref.detectChanges();
  }
}
