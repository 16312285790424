import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { finalize, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpHeaderName } from '../types/http.types';
import { LoadingService } from '../features/shared/loading/loading.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private loadingService: LoadingService,
  ) {}

  intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            // #TODO handle if applicable
          case 500:
            // #TODO show snackbar (Internal Server Error)
            break;
          case 404:
            // #TODO show snackbar (Resource Not Found)
            break;
          case 403:
            // #TODO show snackbar (Forbidden Action)
            break;
        }

        // pass the error on to the next layer
        return throwError(() => error);
      }),
      finalize(() => {
        // update the global loading state
        const requestId = request.headers.get(CustomHttpHeaderName.REQUEST_ID);
        if (requestId) {
          this.loadingService.endAction(requestId);
        }
      }),
    );
  }
}
