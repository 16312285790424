import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingService } from './loading.service';
import { SubscribedComponent } from '../../../lib/subscribed.component';
import { takeUntil } from 'rxjs';
import { NzSpinComponent } from 'ng-zorro-antd/spin';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.less'],
  standalone: true,
  imports: [CommonModule, NzSpinComponent],
})
export class LoadingComponent extends SubscribedComponent implements OnInit {
  @HostBinding('class.is-visible')
  loading: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    this.loadingService
      .watchLoading()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((loading) => {
        // Defer the update to avoid ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
          this.loading = loading;
          this.cdRef.markForCheck();
        });
      });
  }
}
