import { HttpInterceptor, HttpRequest, HttpHandler, HttpEventType } from '@angular/common/http';
import { tap } from 'rxjs/internal/operators/tap';
import { CustomHttpHeaderName } from '../types/http.types';

export class LoggingInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const skipInterceptor = req.headers.get(CustomHttpHeaderName.SKIP_INTERCEPTOR)?.toLowerCase() === 'true';
    if (skipInterceptor) {
      // ✅ Pass the request as-is without modifications
      return next.handle(req);
    }
    console.log('Request is on its way', req.url);

    return next.handle(req).pipe(
      tap((event) => {
        if (event.type === HttpEventType.Response) {
          console.log('Response arrived, body data: ', event.body);
        }
      }),
    );
  }
}
