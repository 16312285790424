import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NzDateConfig } from 'ng-zorro-antd/i18n';
import { WeekDayIndex } from 'ng-zorro-antd/core/time';

@Injectable({
  providedIn: 'root',
})
export class DateConfigService {
  private dateConfigSubject = new BehaviorSubject<NzDateConfig>({
    firstDayOfWeek: 1, // Default value
  });

  dateConfig$ = this.dateConfigSubject.asObservable();

  updateDateConfig(firstDayOfWeek: number): void {
    const newConfig = {
      ...this.dateConfigSubject.value,
      firstDayOfWeek: firstDayOfWeek as WeekDayIndex,
    };
    this.dateConfigSubject.next(newConfig);
  }

  getCurrentDateConfig(): NzDateConfig {
    return this.dateConfigSubject.value;
  }
}
