import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

import { LocationService } from '../../services/location.service';
import { takeUntil } from 'rxjs';

import { Location } from '../../models/location.model';
import { SubscribedComponent } from 'src/app/lib/subscribed.component';
import { ImageUrlPipe } from '../../pipes/image-url.pipe';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrl: './header.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzTypographyModule, ImageUrlPipe],
})
export class HeaderComponent extends SubscribedComponent implements OnInit {
  location: Location;

  constructor(
    private ref: ChangeDetectorRef,
    private locationService: LocationService,
  ) {
    super();
  }

  ngOnInit() {
    this.locationService.locationChanged.pipe(takeUntil(this.destroyed$)).subscribe((location) => {
      this.location = location;
      this.ref.markForCheck();
    });

    this.location = this.locationService.getLocation();
  }
}
