<div class="w-full mt-auto p-4 md:p-6 text-neutral-600">
  © {{ location.businessName }} {{ year }}
  @if (location.billingDetails?.companyName) {
    (operated by {{ location.billingDetails?.companyName }})
  }
  All rights reserved<br />
  <a class="app-footer-link" [routerLink]="['/', location.uuid, 'legal', 'terms']">Terms of Use</a>
  | <a class="app-footer-link" [routerLink]="['/', location.uuid, 'legal', 'privacy']">Privacy Policy</a><br />
  Salon website and booking system powered by
  <a class="app-footer-link" href="https://www.timetailor.com" target="_blank" rel="nofollow">TimeTailor</a>
</div>
