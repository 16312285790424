import { Location as LocationCommon } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

@Component({
  selector: 'app-top-navigation',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzIconModule, NzFlexModule, NzTypographyModule, CommonModule],
  templateUrl: './top-navigation.component.html',
  styleUrl: './top-navigation.component.less',
})
export class TopNavigationComponent {
  @Input() title: string = '';
  @Input() showBackButton: boolean = true;

  constructor(private locationCommon: LocationCommon) {}

  onGoBack(): void {
    this.locationCommon.back();
  }
}
