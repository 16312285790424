<div class="header">
  <div class="flex">
    @if (location?.logoImageUuid) {
      <div class="flex max-h-12 items-center justify-center h-auto">
        <img
          [src]="location?.logoImageUuid | imageUrl: 'locationLogo'"
          alt="{{ location?.businessName }}"
          class="aspect-auto object-contain max-h-12"
        />
      </div>
    } @else {
      <h2 nz-typography>{{ location?.businessName }}</h2>
    }
  </div>
</div>
