import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { NzContentComponent, NzHeaderComponent, NzLayoutComponent, NzSiderComponent } from 'ng-zorro-antd/layout';
import { HeaderComponent } from '../header/header.component';
import { TopNavigationComponent } from '../layout/top-navigation/top-navigation.component';
import { ActivatedRoute, ChildActivationEnd, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SubscribedComponent } from 'src/app/lib/subscribed.component';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { SettingInterface, ThemeService } from '@services/theme.service';
import { WindowService } from '@services/window.service';
import { ThemeOptionsKey } from 'src/app/config/constants';
import { buffer, filter, map, takeUntil } from 'rxjs';
import { SliderRightComponent } from './slider-right/slider-right.component';
import { CommonModule } from '@angular/common';
import { LocationService } from '@services/location.service';
import { Location } from 'src/app/models/location.model';
import { Meta, Title } from '@angular/platform-browser';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NzLayoutComponent,
    NzHeaderComponent,
    NzContentComponent,
    NzSiderComponent,
    HeaderComponent,
    RouterOutlet,
    SliderRightComponent,
    TopNavigationComponent,
    CommonModule,
    FooterComponent,
  ],
})
export class LayoutComponent extends SubscribedComponent implements OnInit {
  private themesOptions: SettingInterface;
  location: Location;
  showHeader: boolean = true;
  showFooter: boolean = true;
  hideSider: boolean;
  isDesktop: boolean = false;
  navTitle: string = '';

  constructor(
    private ref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private nzConfigService: NzConfigService,
    private themesService: ThemeService,
    private windowService: WindowService,
    private locationService: LocationService,
    private titleService: Title,
    private metaService: Meta,
  ) {
    super();
    this.checkDesktop(window.innerWidth);

    // Theme colors
    this.themesService
      .getThemesMode()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((mode) => {
        this.themesOptions = mode;
        const primaryColor = this.themesOptions.color;
        this.navTitle = this.themesOptions.navTitle;
        this.nzConfigService.set('theme', { primaryColor });
        setTimeout(() => this.ref.markForCheck(), 0);
      });

    this.activatedRoute.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      if (params['color']) {
        this.changePrimaryColor(params['color']);
      }

      if (params['theme']) {
        const primaryColor = this.themesService.getPredefinedThemeColor(params['theme']);
        if (primaryColor) {
          this.changePrimaryColor(primaryColor);
        }
      }
    });

    // Show/hide layout components
    const routeEndEvent$ = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));

    this.router.events
      .pipe(
        filter((e) => e instanceof ChildActivationEnd),
        buffer(routeEndEvent$),
        map(([ev]) => (ev as ChildActivationEnd).snapshot.firstChild.data),
        takeUntil(this.destroyed$),
      )
      .subscribe((childRoute) => {
        this.showHeader = childRoute['showHeader'] as boolean;
        this.showFooter = childRoute['showFooter'] as boolean;
        this.hideSider = childRoute['hideSider'] as boolean;
        this.ref.markForCheck();
      });
  }

  ngOnInit() {
    this.locationService.locationChanged.pipe(takeUntil(this.destroyed$)).subscribe((location) => {
      this.location = location;

      this.titleService.setTitle(`Book Your Appointment at ${this.location.businessName}`);
      this.metaService.updateTag({
        name: 'description',
        content: `Easily book your next appointment at ${this.location.businessName} with our simple online scheduling tool. Choose your service, select a time, and confirm your booking in seconds!`,
      });

      if (this.location.themeColor) {
        this.changePrimaryColor(this.location.themeColor);
      }
      this.ref.markForCheck();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkDesktop(event.target.innerWidth);
  }

  private checkDesktop(width: number) {
    this.isDesktop = width >= 768;
    this.ref.markForCheck();
  }

  changePrimaryColor(primaryColor: string): void {
    this.nzConfigService.set('theme', { primaryColor });
    this.themesOptions.color = primaryColor;
    this.setThemeOptions();
  }

  setThemeOptions(): void {
    this.themesService.setThemesMode(this.themesOptions);
    this.windowService.setStorage(ThemeOptionsKey, JSON.stringify(this.themesOptions));
  }
}
