import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppointmentService } from '@services/appointment.service';
import { mergeMap, of } from 'rxjs';
import { Appointment } from 'src/app/models/appointment.model';
import { locationResolver } from './location.resolver';

export const appointmentResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  appointmentService: AppointmentService = inject(AppointmentService),
): Promise<Appointment> => {
  return locationResolver(route, state).pipe(
    // TODO: locationResolver is called again here, but it should be called only once; removed locationResolver from app.routes.ts
    mergeMap((locationData) => {
      if (!locationData) return of(null);

      const isTestMode = route.queryParamMap.get('test') === 'true';
      const utmSource = route.queryParamMap.get('utm_source') ?? 'direct';
      const utmMedium = route.queryParamMap.get('utm_medium') ?? 'organic';
      const utmCampaign = route.queryParamMap.get('utm_campaign') ?? null;
      const utmContent = route.queryParamMap.get('utm_content') ?? null;

      return appointmentService.initAppointment(isTestMode, utmSource, utmMedium, utmCampaign, utmContent);
    }),
  );
};
