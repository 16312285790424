import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { RouterModule } from '@angular/router';
import { LocationService } from '../../services/location.service';
import { takeUntil } from 'rxjs';

import { Location } from '../../models/location.model';
import { SubscribedComponent } from 'src/app/lib/subscribed.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NzTypographyModule, RouterModule],
})
export class FooterComponent extends SubscribedComponent implements OnInit {
  location: Location;
  year: number;

  constructor(
    private ref: ChangeDetectorRef,
    private locationService: LocationService,
  ) {
    super();
  }

  ngOnInit() {
    this.locationService.locationChanged.pipe(takeUntil(this.destroyed$)).subscribe((location) => {
      this.location = location;
      console.log('Location in footer:', this.location.billingDetails?.companyName);
      this.ref.markForCheck();
      this.year = new Date().getFullYear();
    });

    this.location = this.locationService.getLocation();
  }
}
