import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';
import { DateConfigService } from '@services/date-config.service';

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://faf31fea65e2b9bd1499da77cf5f7dc1@o4507504494706688.ingest.us.sentry.io/4507509513322496',
    integrations: [
      // Sentry.browserTracingIntegration(), Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.0, //  1.0 means 100% of the transactions
    release: environment.version,
    // Session Replay
    replaysSessionSampleRate: 0.0, // 1 means 100%.
    replaysOnErrorSampleRate: 0.0, // 1 means 100%.
    beforeSend(event) {
      // You can customize the event here before sending it to Sentry
      // Returning null prevents the event from being sent
      return event;
    },
  });
}

bootstrapApplication(AppComponent, {
  ...appConfig,
  providers: [
    // Provide NZ_DATE_CONFIG with the first day of week dynamically determined
    {
      provide: NZ_DATE_CONFIG,
      useFactory: (dateConfigService: DateConfigService) => {
        return dateConfigService.getCurrentDateConfig();
      },
      deps: [DateConfigService],
    },
    ...(appConfig.providers || []),
  ],
}).catch((err) => console.error(err));
